import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import closeIcon from '../img/icons/close.svg';

const Modal = ({classes, showModal, setShowModal, content}) => {
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        if (showModal && !document.body.classList.contains('modal-active')) {
            setTimeout(() => {
                document.body.classList.add('modal-active');
            }, 50);
        }
        if (!showModal && document.body.classList.contains('modal-active')) {
            document.body.classList.remove('modal-active');
        }
        return () => {
            if (document.body.classList.contains('modal-active')) {
                document.body.classList.remove('modal-active');
            }
            if (showModal) {
                setShowModal(!showModal);
            }
        };
    }, [showModal]);

    useEffect(() => {
        if (showModal) {
            setShowModal(null);
        }
    }, [pathname]);

    if (!showModal) {
        return;
    }

    return (
        <section className={`modal ${classes}`}>
            <div className="overlay">
                <span className="loading-decor-l" />
                <span className="loading-decor-r" />
            </div>
            <div className="content relative">
                <div className="container">
                    <header className="flex items-center justify-end py-12">
                        <Link onClick={() => setShowModal(false)} className="btn btn-header">
                            <span><img src={closeIcon} className="w-6 h-6" alt="menu icon" /></span>
                        </Link>
                    </header>
                </div>
                {content}
            </div>
        </section>
    );
};

export default Modal;
