import React, { useEffect, useState } from 'react';
import { getResultsData } from "../utils/helper";

const Results = () => {
    const [resultsData, setResultsData] = useState(null);

    useEffect(() => {
        getResultsData().then(data => {
            if(data && Object.keys(data).length && Object.keys(data)[0] === '1') {
                setResultsData(data)
            }
        });

        if (!document.body.classList.contains('results-page')) {
            document.body.classList.add('results-page')
        }

        return () => {
            if (document.body.classList.contains('results-page')) {
                document.body.classList.remove('results-page')
            }
        };
    }, []);

    return (
        <main>
            <main className="flex">
                <div className="flex flex-col justify-center align-center mx-auto">
                    <div className="pt-6 pb-8 xl:py-20 text-center">
                        <div className="mb-8">
                            <div className="container-sm">
                                <h1 className="px-8 md:px-0">Meet the Christmas Champions!</h1>
                            </div>
                            <div className="container-xs">
                                <p>Every day brings a new chance to shine! Will your name make it onto Santa’s nice list this year?</p>
                            </div>
                        </div>
                        <div className="container-sm result-block">
                            {resultsData && <ul>
                                {Object.keys(resultsData).map((i) => {
                                    return (
                                        <li key={i}>
                                            <div>
                                                <h3>{`Day ${i}`}</h3>
                                                <p>Winner: <strong>{resultsData[i].winner}</strong></p>
                                                <p>Runner up: <strong>{resultsData[i].runner_up}</strong></p>
                                                <div className="decor-item-left" />
                                                <div className="decor-item-right" />
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>}
                            {!resultsData && <ul>
                                <li className="message">
                                    <div>
                                        <p>
                                            We are waiting for our first winners
                                        </p>
                                        <div className="decor-item-left" />
                                        <div className="decor-item-right" />
                                    </div>
                                </li>
                            </ul>}
                        </div>
                    </div>
                </div>
            </main>
        </main>
    );
};

export default Results;
