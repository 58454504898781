import React from 'react';
import Facebook from '../img/icons/facebook.svg';
import Instagram from '../img/icons/instagram.svg';
import Linkedin from '../img/icons/linkedin.svg';
import X from '../img/icons/X.svg';

const Footer = () => {

    return (
        <footer className="footer">
            <div className="container text-center 2xl:flex 2xl:justify-between 2xl:flex-row-reverse py-4 2xl:py-6">
                <ul className="social">
                    <li><a href="https://twitter.com/aldiaustralia" target="_blank" rel="noreferrer">
                        <img src={X} alt="X" />
                    </a></li>
                    <li><a href="https://www.facebook.com/ALDI.Australia" target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="Facebook" />
                    </a></li>
                    <li><a href="https://www.instagram.com/aldiaustralia/?hl=en" target="_blank"  rel="noreferrer">
                        <img src={Instagram} alt="Instagram" />
                    </a></li>
                    <li><a href="https://www.linkedin.com/company/aldi-stores-australia/?originalSubdomain=au"
                           target="_blank"
                           rel="noreferrer">
                        <img src={Linkedin} alt="Linkedin" />
                    </a></li>
                </ul>
                <ul className="footer-menu">
                    <li><a href="http://myaldi.com.au/" target="_blank" rel="noreferrer">MyALDI.com.au</a></li>
                    <li><a href="https://www.aldi.com.au/" target="_blank" rel="noreferrer">ALDI Supermarkets</a></li>
                    <li><a href="https://www.aldi.com.au/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                    <li><a href="https://www.aldi.com.au/data-protection/" target="_blank" rel="noreferrer">Data Protection</a></li>
                    <li><a href="https://www.aldi.com.au/legal-notice/" target="_blank" rel="noreferrer">Legal Notice</a></li>
                    <li><a href="https://security.aldi-sued.com/" target="_blank" rel="noreferrer">Security Notice</a></li>
                </ul>
                <div className="copyright 2xl:min-w-[158px] 2xl:text-left">
                    <a href="https://www.aldi.com.au/" target="_blank" rel="noreferrer">© 2024 by ALDI</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
