import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    useEffect(() => {
        if (!document.body.classList.contains('home-page')) {
            document.body.classList.add('home-page')
        }
        return () => {
            if (document.body.classList.contains('home-page')) {
                document.body.classList.remove('home-page')
            }
        };
    }, []);

    return (
        <main className="flex">
            <div className="flex flex-col justify-center align-center mx-auto">
                <div className="pb-8 text-center">
                    <div className="mb-8">
                        <div className="container-sm">
                            <h1>Welcome to the 2024 ALDI Advent Calendar</h1>
                        </div>
                        <div className="container-xs">
                            <p>Get ready for daily festive fun, from mind-bending puzzles and quizzical quizzes
                                to jolly jests and merry conquests. Bring your competitive spirit and go a little extra this festive season to win great prizes.</p>
                        </div>
                    </div>
                    <Link to="/calendar" className="btn"><span>Enter now</span></Link>
                </div>
            </div>
        </main>
    );
};

export default Home;
